<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="mr-auto">
            <ul
              class="nav nav-pills"
              v-if="checkAccess('setting', 'ru_item_numbering')"
            >
              <li class="nav-item">
                <router-link to="/data-item-penomoran" class="nav-link"
                  >Item Penomoran</router-link
                >
              </li>
              <li
                class="nav-item"
                v-if="checkAccess('setting', 'cru_item_payroll')"
              >
                <router-link to="/data-item-penggajian" class="nav-link"
                  >Item Penggajian
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/data-item-synchronize" class="nav-link active"
                  >Sinkronisasi
                </router-link>
              </li>
              <li class="nav-item" v-if="checkAccess('setting', 'sync')">
                <router-link to="/data-fee-marketing" class="nav-link"
                  >Fee Marketing
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-table">
          <div class="row">
            <div class="col-6">
              <div>Pilih sistem yang akan disinkronisasi data :</div>
            </div>
            <div class="col-6">
              <div class="box-danger float-right">
                <div class="title">Application Key</div>
                <div class="value">{{ appKey }}</div>
              </div>
            </div>
          </div>
          <div class="mt-1 d-flex">
            <div
              class="card-app d-flex justify-content-center"
              v-for="(value, index) in listData"
              @click="setSync(value)"
              :key="index"
              :style="{
                cursor: 'pointer',
              }"
            >
              <img
                :src="getImage(value.image)"
                :alt="value.image"
                class="align-self-center"
                :style="{
                  width: '100%',
                  filter: `grayscale(${
                    !value.isIntegrations || value.isIntegrations == 'false'
                      ? '100'
                      : '0'
                  }%)`,
                }"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <div
    class="modal fade"
    id="syncModal"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">{{ labelModal }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="onCheck" v-if="stateSync == 'check'">
            <div class="modal-body mt-2">
              <div class="form-group mb-3">
                <label>Sistem terpilih</label>
                <div class="d-flex justify-content-center flex-wrap">
                  <img
                    :src="getImage(formData.image)"
                    :alt="formData.image"
                    class="align-self-center"
                    :style="{ width: '150px', height: 'auto' }"
                  />
                  <!-- <h6
                    :style="{
                      width: '100%',
                      textAlign: 'center',
                      margin: 0,
                    }"
                  >
                    {{ formData.nama }}
                  </h6> -->
                </div>
              </div>

              <div class="form-group mb-2">
                <label
                  >Silakan masukkan Application Key sistem yang dipilih</label
                >
                <input
                  type="text"
                  placeholder="Silakan masukkan Application Key"
                  v-model="formData.app_key"
                  class="form-control text-uppercase"
                  :class="{
                    'is-invalid': formError && formError.app_key,
                  }"
                  @keyup="formChange('check', 'app_key')"
                />
                <div class="form-error" v-if="formError && formError.app_key">
                  {{ formError.app_key }}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" :disabled="isCheck" class="btn btn-blue">
                <span class="fas fa-spin fa-spanner" v-if="isCheck"></span
                >{{ isCheck ? " Tunggu Sebentar..." : "Next" }}
              </button>
            </div>
          </form>
          <form @submit.prevent="onSetup" v-if="stateSync == 'setup'">
            <div class="modal-body mt-2">
              <div class="form-group mb-2">
                <label>Company</label>
                <div class="d-flex justify-content-center flex-wrap">
                  <img
                    :src="getImage(formSetup.image, 'company')"
                    :alt="formSetup.image"
                    class="align-self-center"
                    :style="{ width: '110px', height: 'auto' }"
                  />
                  <h6
                    :style="{
                      width: '100%',
                      textAlign: 'center',
                      margin: 0,
                    }"
                  >
                    {{ formSetup.name }}
                  </h6>
                </div>
              </div>
              <div class="form-group mb-2">
                <label>Password anda</label>
                <div class="position-relative" data-toggle="change_password">
                  <input
                    :type="isPassword ? 'password' : 'text'"
                    placeholder="Masukkan password anda"
                    v-model="formSetup.password"
                    class="form-control"
                    :class="{
                      'is-invalid': formErrorSetup && formErrorSetup.password,
                    }"
                    @keyup="formChange('setup', 'password')"
                  />
                  <span
                    class="form-icon"
                    @click="isPassword = !isPassword"
                    :class="{
                      'is-error': formErrorSetup && formErrorSetup.password,
                    }"
                  >
                    <i
                      class="far"
                      :class="isPassword ? 'fa-eye-slash' : 'fa-eye'"
                    ></i>
                  </span>
                </div>
                <div
                  class="form-error"
                  v-if="formErrorSetup && formErrorSetup.password"
                >
                  {{ formErrorSetup.password }}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" :disabled="isSetup" class="btn btn-blue">
                <span class="fas fa-spin fa-spanner" v-if="isSetup"></span
                >{{ isSetup ? " Tunggu Sebentar..." : "Hubungkan sistem" }}
              </button>
            </div>
          </form>

          <form @submit.prevent="onRemove" v-if="stateSync == 'detail'">
            <div class="modal-body mt-2">
              <div class="form-group mb-2">
                <label>System terpilih</label>
                <div class="d-flex justify-content-center flex-wrap">
                  <img
                    :src="getImage(formDetail.application_image)"
                    :alt="formDetail.application_image"
                    class="align-self-center"
                    :style="{ width: '150px', height: 'auto' }"
                  />
                  <h6
                    :style="{
                      width: '100%',
                      textAlign: 'center',
                      marginTop: '5px',
                    }"
                  >
                    <!-- {{ formDetail.application_name }} -->
                  </h6>
                </div>
              </div>
              <div class="form-group mb-2">
                <label>Company</label>
                <div class="d-flex justify-content-center flex-wrap">
                  <img
                    :src="getImage(formDetail.image, 'company')"
                    :alt="formDetail.image"
                    class="align-self-center"
                    :style="{ width: '110px', height: 'auto' }"
                  />
                  <h6
                    :style="{
                      width: '100%',
                      textAlign: 'center',
                      marginTop: '5px',
                    }"
                  >
                    {{ formDetail.name }}
                  </h6>
                </div>
              </div>

              <div class="form-group mb-2">
                <label>Password anda</label>
                <div class="position-relative" data-toggle="change_password">
                  <input
                    :type="isPassword ? 'password' : 'text'"
                    placeholder="Masukkan password anda"
                    v-model="formDetail.password"
                    class="form-control"
                    :class="{
                      'is-invalid': formErrorDetail && formErrorDetail.password,
                    }"
                    @keyup="formChange('detail', 'password')"
                  />
                  <span
                    class="form-icon"
                    @click="isPassword = !isPassword"
                    :class="{
                      'is-error': formErrorDetail && formErrorDetail.password,
                    }"
                  >
                    <i
                      class="far"
                      :class="isPassword ? 'fa-eye-slash' : 'fa-eye'"
                    ></i>
                  </span>
                </div>
                <div
                  class="form-error"
                  v-if="formErrorDetail && formErrorDetail.password"
                >
                  {{ formErrorDetail.password }}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" :disabled="isRemove" class="btn btn-red">
                <span class="fas fa-spin fa-spanner" v-if="isRemove"></span
                >{{ isRemove ? " Tunggu Sebentar..." : "Putuskan Sistem" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
// import { showAlert } from "../../../helper";
// import Select2 from 'vue3-select2-component';

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import { checkModuleAccess, showAlert, checkRules, cksClient } from "../../../helper";
import { DATA } from "../../../constans/Url";
import {
  get_DetailIntegrations,
  get_ListApplication,
  post_CheckIntegrations,
  post_InitIntegrations,
  post_RemoveIntegrations,
} from "../../../actions/integrations";
// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    // Select2
    // TableLite
  },
  data() {
    return {
      checkAccess: checkModuleAccess,
      appKey: "",
      isSearch: false,
      search: "",
      labelModal: "Sinkronisasi Sistem",
      formData: {
        nama: "",
        image: "",
        to_application: "",
        app_key: "",
      },
      rules: [
        {
          app_key: {
            required: true,
          },
        },
      ],
      formDetail: {
        session: "",
        application_name: "",
        application_image: "",
        name: "",
        image: "",
        password: "",
        to_application: "",
      },
      detailRules: {
        session: {
          required: true,
        },
        password: {
          required: true,
        },
      },
      formSetup: {
        name: "",
        image: "",
        pass_key: "",
        password: "",
        to_application: "",
      },
      setupRules: {
        pass_key: {
          required: true,
        },
        password: {
          required: true,
        },
        to_application: {
          required: true,
        },
      },
      formError: [],
      formErrorSetup: [],
      formErrorDetail: [],
      isSetup: false,
      isPassword: true,
      isRemove: false,
      stateSync: "check",
      isCheck: false,
      listData: [],
    //   id_company: cksClient().get("_account").id_company,
      dataTable: "",
      appConfig: {
        rawuh: 'appRawuh',
        finanta: 'appFinanta',
      }
    };
  },

  created() {
    this.get_List();
  },

  methods: {
    getImage(image, type = "brand") {
      if (type == "brand") {
        return DATA.BRAND(image);
      } else if (type == "company") {
        return DATA.COMPANY(image);
      }
    },
    get_List() {
      get_ListApplication((res) => {
        const { list, app_key } = res;
        this.appKey = app_key;
        this.listData = list;
        setTimeout(() => {
          this.tooltip();
        }, 500);
      });
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    setSync(value) {
      this.isPassword = true;
      if (value.isIntegrations == "false") {
        this.labelModal = "Sinkronisasi Sistem";
        this.stateSync = "check";
        this.formData.image = value.image;
        this.formData.nama = value.nama;
        this.formData.to_application = value.uniq;
        this.formData.app_key = "";
        this.openModal(true);
      } else {
        this.stateSync = "detail";
        get_DetailIntegrations(
          value.isIntegrations,
          (res) => {
            const { data } = res;
            this.formDetail.to_application = value.uniq
            this.formDetail.application_name = value.nama;
            this.formDetail.application_image = value.image;
            this.formDetail.name = data.nama;
            this.formDetail.session = data.session;
            this.formDetail.image = data.image;
            this.formDetail.password = "";

            this.openModal(true);
          },
          () => {
            showAlert(this.$swal, {
              title: "ERROR!",
              msg: `Terjadi kesalahan, tidak bisa mendapatkan sistem detail`,
              showCancelButton: true,
              showConfirmButton: false,
            });
          }
        );
      }
    },

    openModal(isShow = true) {
      if (!isShow) {
        $("#syncModal").modal("hide");
      } else {
        $("#syncModal").modal("show");
      }
    },

    async formChange(tipe, key) {
      if (tipe == "check") {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }

        var check = await checkRules(this.rules, this.formData);
        this.formError = check.error;
      } else if (tipe == "setup") {
        if (Object.hasOwnProperty.call(this.setupRules, key)) {
          this.setupRules[key].changed = true;
        }

        var checkSetu = await checkRules(this.setupRules, this.formSetup);
        this.formErrorSetup = checkSetu.error;
      } else if (tipe == "detail") {
        if (Object.hasOwnProperty.call(this.detailRules, key)) {
          this.detailRules[key].changed = true;
        }

        var checkDetail = await checkRules(this.detailRules, this.formDetail);
        console.log(checkDetail);
        this.formErrorDetail = checkDetail.error;
      }
    },

    async onCheck() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        this.isCheck = true;
        post_CheckIntegrations(
          this.formData,
          (res) => {
            const { data } = res;
            this.isCheck = false;
            this.formSetup.name = data.name;
            this.formSetup.pass_key = data.unique_kay;
            this.formSetup.image = data.image;
            this.formSetup.to_application = data.to_application;
            this.formSetup.password = "";

            this.stateSync = "setup";
          },
          (err) => {
            var msg = "Terjadi kesalahan, silakan coba lagi";
            if (err.msg == "Application not found") {
              msg = "Application key tidak valid, silakan cek kembali";
            }

            this.isCheck = false;
            this.openModal(false);
            setTimeout(() => {
              showAlert(this.$swal, {
                title: "ERROR!",
                msg: msg,
                showCancelButton: true,
                showConfirmButton: false,
              });
            }, 500);
          }
        );
      }
    },

    async onSetup() {
      for (const key in this.setupRules) {
        if (Object.hasOwnProperty.call(this.setupRules, key)) {
          this.setupRules[key].changed = true;
        }
      }
      var checkSetu = await checkRules(this.setupRules, this.formSetup);
      this.formErrorSetup = checkSetu.error;
      if (checkSetu.success) {
        this.isSetup = true;
        post_InitIntegrations(
          this.formSetup,
          (res) => {
            if (res.is_success) {
              this.isSetup = false;
              this.openModal(false);
              this.get_List();
              var dataAccount = cksClient().get("_account");
              dataAccount.checkSync[this.appConfig[this.formSetup.to_application]] = true
              cksClient().set("_account", JSON.stringify(dataAccount))
              setTimeout(() => {
                showAlert(this.$swal, {
                  title: "SUCCESS!",
                  msg: "System telah terhubung",
                });
              }, 500);
            }
          },
          (err) => {
            var msg = `Terjadi kesalahan, sistem tidak dapat terhubung`;
            if (err.msg == "Pass key not found") {
              msg = `Terjadi kesalahan, silakan ulangi kembali nanti`;
            } else if (err.msg == "password is invalid") {
              msg = "Password anda salah, silakan cek kembali";
            }
            this.isSetup = false;
            setTimeout(() => {
              showAlert(this.$swal, {
                title: "ERROR!",
                msg: msg,
                showCancelButton: true,
                showConfirmButton: false,
              });
            }, 500);
          }
        );
      }
    },

    async onRemove() {
      for (const key in this.detailRules) {
        if (Object.hasOwnProperty.call(this.detailRules, key)) {
          this.detailRules[key].changed = true;
        }
      }
      var check = await checkRules(this.detailRules, this.formDetail);
      this.formErrorDetail = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "WARNING!",
          msg: "Apakah anda yakin, akan memutuskan sistem yang telah terhubung?",
          showCancelButton: true,
          confirmButtonText: "Ya, hapus sistem",
          onSubmit: () => {
            this.isRemove = true;

            post_RemoveIntegrations(
              this.formDetail,
              (res) => {
                if (res.is_success) {
                  this.isRemove = false;
                  this.openModal(false);
                  this.get_List();
                  var dataAccount = cksClient().get("_account");
                  dataAccount.checkSync[this.appConfig[this.formDetail.to_application]] = false
                  cksClient().set("_account", JSON.stringify(dataAccount))
                  setTimeout(() => {
                    showAlert(this.$swal, {
                      title: "SUCCESS!",
                      msg: "Sistem berhasil diputuskan",
                    });
                  }, 500);
                }
              },
              (err) => {
                var msg = `Terjadi kesalahan, tidak dapat memutuskan sistem`;
                if (err.msg == "Data not found") {
                  msg = `Terjadi kesalahan, sistem tidak ditemukan`;
                } else if (err.msg == "password is invalid") {
                  msg = "Password anda salah, silakan cek kembali";
                }
                this.isRemove = false;
                setTimeout(() => {
                  showAlert(this.$swal, {
                    title: "ERROR!",
                    msg: msg,
                    showCancelButton: true,
                    showConfirmButton: false,
                  });
                }, 500);
              }
            );
          },
        });
      }
    },
  },
};
</script>

<style scoped>
button.btn-add {
  border: none !important;
}

.card-table {
  margin-top: 0px !important;
}

.card-app {
  border: 1px solid #e3e3e3;
  padding: 0px 30px;
  border-radius: 4px;
  position: relative;
  min-height: 120px;
  min-width: 200px;
  margin-right: 20px;
  margin-top: 20px;
}

.card-app img {
  object-fit: cover;
}

.checkbox {
  position: absolute;
  left: 10px;
  top: 10px;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid #d0d0d0;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #fff;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"]:checked {
  background-color: #68caf8!important;
  border: none;
}

.section .btn-save {
  padding: 0.3rem 0.8rem !important;
  font-size: 12px !important;
}
</style>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>
